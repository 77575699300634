<template>
    <v-container class="fill-height white--text" fluid>
        <v-row class="fill-height" justify="center" no-gutters>
            <v-col cols="12" sm="8" md="4" xl="2">
                <div class="d-flex flex-column fill-height justify-space-between">
                    <div></div>

                    <div class="text-center d-flex flex-column align-center">
                        <div class="mb-8">
                            <v-img src="@/assets/logo_w.svg"/>
                        </div>

                        <h1 class="text-uppercase">Пекарня <span class="primary--text">SHEN</span></h1>
                        <div class="headline">Печем и доставляем свежий хлеб в Тюмени</div>
                    </div>

                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>

                    <div>
                        <v-btn
                            block
                            color="primary"
                            :to="{name: 'auth'}"
                        >
                            Войти
                        </v-btn>
                    </div>

                    <div></div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {
        VBtn,

        VContainer,
        VRow,
        VCol,

        VImg,
    } from 'vuetify/lib'

    export default {
        name: 'Start',

        components: {
            VBtn,

            VContainer,
            VRow,
            VCol,

            VImg,
        },

        data() {
            return {}
        },
    }
</script>

<style lang="scss" scoped>
    h1 {
        font-family: 'Oswald', sans-serif;
        font-size:   2.75rem;
    }
</style>
